import { STATUSES } from '@roolz/types/api/companyMembers'
import { ItemsResponse } from '@roolz/types/api/utils'
import { Company, Department, Role } from './companies'
import { Country } from '../custom'
import { LoggableSocialNetwork, OS } from './index'
import { ISOString, ID, Url } from '../scalars'

export const SUCCESS_RESPONSE = 'OK'

export interface Device {
  id: string,
  installation_id: string
  profile_id: string
  client_name: string
  system_version: string
  app_version: string
  appsflyer_id: string
  push_enabled: true
  chats_wt_enabled: true
  gps_enabled: true
  os_type: 'windows' | 'android' | 'ios' | 'macos' | 'unix'
}

export interface AuthDevice {
  installation_id: string
  client_name: string
  system_version: string
  app_version: string
  os_type: OS
}


export interface RefreshTokenRequest {
  token: string
}

export type RefreshTokenResponse = AuthTokenInfo

// TODO no signature in swagger
export interface testToken {
  token: string
}

export interface checkTokenExpiration {
  token: string
}

export interface LoginByEmailRequest {
  auth_email: string,
  auth_password: string,
  device: AuthDevice
}

export interface AuthTokenInfo {
  token: string
  refresh_expires_at: string
  expires_at: string
}

export interface AuthResponse {
  rabbit_username: string,
  rabbit_password: string,

  auth_token: AuthTokenInfo

  device: Device,
  profile: Profile
}

export interface loginByMagicLinkToken {
  device: AuthDevice
  invite_id?: string
  device_fingerprint?: string
  token: string
}

export interface LoginBySmsRequest {
  code: string
  sms_id: string
  device: AuthDevice
}

export interface getResetPasswordLink {
  auth_email: string
}

export interface getMagicLink {
  auth_email: string
}

export interface loginBySocialNetwork {
  social_name: LoggableSocialNetwork

  social_token: string
  device: AuthDevice
  // invite_id?: string
  // device_fingerprint?: string
  // email?: string
}

export interface loginByMagicLink {
  device: AuthDevice
  // invite_id?: string,
  // device_fingerprint?: string,
  token: string,
}

export interface checkIfEmailBusyRequest {
  email: string
}

export interface checkIfEmailBusyResponse {
  is_busy: boolean
}

export interface Avatar {

}

export interface SendSmsRequest {
  auth_phone: string
  hash: string
}

export interface SendSmsResponse {
  sms_id: string
  is_phone_exists: boolean
}

export interface GetSearch {
  query: string
  exclude_banned?: boolean
  kinds?: string
  is_registered_contact?: boolean
  offset?: number
  limit?: number
}

export interface GetContactListRequest {
  limit: number
  offset: number
  company_id?: string
  types?: ContactType
  remove_duplications?: boolean
  exclude_profiles?: Profile['id']
  //orders must be array of items in format "field:asc,field1:desc"
  order?: string
}

export type GetContactListResponse = ItemsResponse<ContactInfo>

export interface SendSmsToChangePhoneRequest {
  new_phone: string
  hash: string
}

export interface SendSmsToChangePhoneResponse {
  sms_id: string,
  is_phone_exists: boolean
}

export type CompanyInvitation = {
  id: string
  created_at: string
  created_by: string
  expires_at: string
  updated_at: string
  email: string | null
  invited_user_id: string | null
  role: string
  status: STATUSES
  magic_hash: string
  company: Company
  profile: Profile | null
}

export type Profile = {
  id: ID
  email?: string | null
  phone?: string | null

  nickname?: string | null
  country?: Country | null
  is_filled?: boolean
  color?: string
  lang_code?: string
  active_space_company_id?: Company['id'] | null
  personal_companies?: string | null
  personal_key: string
  last_login_at?: string
  // reg_method

  phone_verified: boolean
  email_verified: boolean

  has_auth_password: boolean

  role: ProfileRole
  register_country_code?: Country["alpha2"]
  last_login_country_code?: Country["alpha2"]
  google_id: string
  facebook_id: string
  apple_id: string

  relationships?: ProfileRelationships
  profile_view_info: ProfileViewInfo | null

  last_action_time?: string
  companies?: UserCompany[]
  company_invitations?: CompanyInvitation[]

  created_at?: string
  deleted_at?: ISOString | null
  must_delete_at?: ISOString | null
}

export type PatchProfileRequest = Partial<Pick<Profile,
  'lang_code' |
  'nickname' |
  'active_space_company_id'
>> & {
  country_id?: Country['id'] | null
  profile_view_info?: Partial<ProfileViewInfo>
}

export enum ProfileRole {
  Client = 'client',
  Admin = 'admin',
  Moderator = 'moderator',
  System = 'system'
}

export interface ProfileViewInfo {
  first_name:	string | null
  middle_name:	string | null
  last_name:	string | null
  work_email:	string | null
  work_phone:	string | null
  own_email:	string | null
  own_phone:	string | null
  avatar:	string | null
  skype: string | null
  site: Url | null
  about: string | null
}

export interface ProfileRelationships {
  is_personal_contact: boolean
  is_colleague: boolean
  is_banned: boolean
  is_banned_me: boolean
  is_dialog_wt_on: boolean
}

export interface deleteOwnProfileRequest {
  deletion_reason?: string
}

export interface CheckNicknameBusynessRequest {
  nickname: string
}

export interface CheckNicknameBusynessResponse {
  is_busy: boolean
}

export interface setPasswordRequest {
  new_password: string
}

export interface changePasswordRequest {
  password?: string | null
  new_password: string
}

export type changePasswordResponse = Profile

export interface deleteOwnEmailRequest {
  password?: string | null
}

export interface ValidateCurrentPasswordRequest {
  password?: string | null
}

export interface deleteOwnPasswordRequest {
  password?: string | null
}

export type GetProfileByIdRequest = {
  allow_deleted?: boolean
}

export type deleteOwnEmailResponse = Profile

export type updateOwnEmailRequest = {
  new_email: Profile['email']
  password?: string | null
}
export type updateOwnEmailResponse = Profile

export type updateOwnPhoneRequest = {
  code: string
  sms_id: string
  password?: string | null
}
export interface DeleteAuthPhoneRequest {
  password?: string | null
}

export type updateOwnPhoneResponse = Profile
export type deleteOwnPhoneResponse = Profile
export type deleteOwnPasswordResponse = Profile

export type UserCompany = Pick<Company,
  'id' |
  'name' |
  'my_role' |
  'color' |
  'logo_path' |
  'is_profile_public'
> & {
  user_departments: Department[]
  user_roles: Role[]
  is_verified?: boolean
  is_active?: boolean
}

export enum ContactKind {
  phone = 'phone',
  personal = 'personal',
  company = 'company',
  global = 'global'
}

// export interface ContactInfo {
//   profile: Profile
//   phone?: string
//   kind?: ContactKind
//   is_ban: boolean
//   company_id?: string
// }

export enum ContactType {
  PERSONAL = 'personal',
  COMPANY = 'company'
}


export type ContactInfo = {
  company_id: null | string
  type: ContactType
  profile: Profile
}

export interface ClientConfigResponse {
  rabbit_socket: {
    host: string
    port: number
    virtual_host: string
  },
  rabbit_queue: string
  server_time: number
  upload_url: string
  static_url: string
  fcm_token: string
}

export interface untieSocialNetworkRequest {
  social_name: LoggableSocialNetwork
  password: string | null
}

export interface TiedSocialNetworks {
  google_id: string | null
  facebook_id: string | null
  apple_id: string | null
}

export interface LoadProfilesByIdsRequest {
  profile_ids: Profile["id"][]
  offset?: number
  limit?: number
}

export interface GetAllContactsResponse {
  banned_ids: Array<Profile["id"]>
  colleagues_ids: Record<Company["id"], Profile["id"]>
  personal_ids: Array<Profile["id"]>
}
