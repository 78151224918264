import { Events, bus } from '@/events'
import { useEffect } from 'react'

export function useGlobalEvent<Key extends keyof Events>(name: Key, handler: (payload: Events[Key]) => void) {
  useEffect(() => {
    bus.on(name, handler)

    return () => bus.off(name, handler)
  }, [])
}
