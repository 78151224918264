// @ts-ignore
import R from 'core-js/stable/index.js'
// eslint-disable-next-line no-unused-expressions
R
import 'react-app-polyfill/stable'
import '@/plugins/i18n'
import '@/plugins/yup'
import '@/plugins/sentry'
import { store } from '@/store/store'
import { StoreContext } from '@/store/store.context'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import { SnackbarsContainer } from '@roolz/sdk/components/snackbars'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Jivosite } from '@/components/plugins/Jivosite/Jivosite'
// @ts-ignore

declare global {
  interface Window {
    isInteracted: boolean
  }
}

// eslint-disable-next-line
const root = createRoot(document.getElementById('root')!)
root.render(
  <StoreContext.Provider value={store}>
    <BrowserRouter>
      <Jivosite/>

      <App/>
    </BrowserRouter>
  </StoreContext.Provider>
)

reportWebVitals(console.log)
