import { useNavigateToModal } from '@/config/routes'
import { GlobalModalBackdrop } from '@/global-modals/components/GlobalModalBackdrop/GlobalModalBackdrop'
import { GlobalModalsContext } from '@/global-modals/GlobalModalsContext'
import { globalModalsHistory } from '@/global-modals/globalModalsHistory'
import { OptionsType } from '@/global-modals/GlobalModalsManager'
import { usePrevious } from '@roolz/sdk/hooks/helpers/usePrevious'
import { useForceUpdate } from '@roolz/sdk/hooks/useForceUpdate'
import { observer } from 'mobx-react-lite'
import { Fragment, memo, useContext, useEffect, useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { ModalType } from '../const'
import FocusLock from 'react-focus-lock'

// const history: History = {
//   [ModalType.OVERLAY]: [],
//   [ModalType.POPUP]: []
// }

export const GlobalModalContainer = observer(function GlobalModalContainer() {
  const { modalManager } = useContext(GlobalModalsContext)

  const location = useLocation()
  const prevLocation = usePrevious(location)
  const navigate = useNavigateToModal()

  const forceUpdate = useForceUpdate()
  const updateRequested = useRef(false)

  function handleModalsChange() {
    // if(!updateRequested.current) {
    forceUpdate()
    // updateRequested.current = true
    // }
  }

  useLayoutEffect(() => {
    // updateRequested.current = false
  })

  useEffect(() => {
    if(modalManager) {
      modalManager.subscribe(handleModalsChange)
    }
  }, [modalManager])

  useEffect(() => {
    if(prevLocation?.state?.backgroundLocation && !location?.state?.backgroundLocation) {
      if(modalManager.visibleModals.length) {
        modalManager.closeAll()
      }
    }
  }, [location, prevLocation])

  if(!modalManager) {
    return null
  }

  // function onOverlayBack() {
  //   if(visibleOverlayModals.length >= 2) {
  //     return visibleOverlayModals[visibleOverlayModals.length - 1]
  //   }
  // }

  const mountedModals = modalManager.mountedModals

  const overlayModals = mountedModals
    .filter(({ modal }) => modal?.meta?.type === ModalType.OVERLAY)
  const visibleOverlayModals = overlayModals.filter(({ isVisible }) => isVisible)

  const popups = mountedModals
    .filter(({ modal }) => modal?.meta?.type === ModalType.POPUP)
  const visiblePopups = popups.filter(({ isVisible }) => isVisible)

  function handleBackdropClick() {
    if(modalManager) {
      visibleOverlayModals.forEach(modal => {
        if(modal.config.onClose) {
          modal.config.onClose()
        } else {
          modalManager.close(modal.modal.id)
        }
      })
      // if(modalManager.activeModal?.config.closeOnBackdropClick) {
      //   modalManager.close(modalManager.activeModal.id)
      // }
    }
  }

  /*
   Check if it needs to add info to history
   */
  if(visibleOverlayModals.length > 0) {
    // const current = visibleOverlayModals.at(-1)
    //
    // if(current) {
    //   if(!history[ModalType.OVERLAY].length) {
    //     history[ModalType.OVERLAY].push(current)
    //   } else {
    //     const last = history[ModalType.OVERLAY].at(-1)
    //
    //     if(
    //       last?.modal?.id !== current?.modal?.id
    //       || !isEqual(last?.props, current?.props)
    //     ) {
    //       history[ModalType.OVERLAY].push(current)
    //     }
    //   }
    // }
  } else {
    globalModalsHistory['overlay'].length = 0
  }

  if(visiblePopups.length > 0) {
    // const current = visiblePopups.at(-1)

    // if(current) {
    //   if(!history[ModalType.POPUP].length) {
    //     history[ModalType.POPUP].push(current)
    //   } else {
    //     const last = history[ModalType.POPUP].at(-1)
    //
    //     if(
    //       last?.modal?.id !== current?.modal?.id
    //       && !isEqual(last?.props, current?.props)
    //     ) {
    //       history[ModalType.POPUP].push(current)
    //     }
    //   }
    // }
  } else {
    globalModalsHistory['popup'].length = 0
  }


  function goBack(type: ModalType) {
    if(globalModalsHistory[type].length >= 2) {
      globalModalsHistory[type].splice(-1)
      const last = globalModalsHistory[type].at(-1)

      if(last) {
        navigate(last)
        // console.log('BACK true')
        // modalManager.open(last.modal.id, {
        //   props: last.props,
        //   config: last.config
        // })
      }
    } else {
      console.log('CANT GET BACK ' + type + ' BECAUSE HISTORY HAS LESS THAN 2 ITEMS')
    }

  }

  return (
    <FocusLock disabled={visibleOverlayModals.length === 0}>
      <div style={{ zIndex: 1200, position: 'relative' }}>
        <GlobalModalBackdrop
          show={visibleOverlayModals?.length > 0}
          onClick={handleBackdropClick}
        />

        {overlayModals.map((modal) => (
          <Fragment key={modal.modal.id}>
            <modal.modal.Component
              transition={globalModalsHistory[ModalType.OVERLAY].length < 2}

              {...modal.modal.defaultProps}
              {...modal.props}

              isShown={modal.isVisible}
              setIsShown={(val: boolean, options?: OptionsType) => {
                if(!val) {
                  modalManager.close(modal.modal.id, options)
                  if(modal.config?.onClose) {
                    modal.config.onClose()
                  }
                }
              }}
              onBack={globalModalsHistory[ModalType.OVERLAY].length <= 1
                ?  undefined
                : () => {
                  if(modal?.config?.onBack) {
                    modal.config.onBack()
                  }

                  goBack(ModalType.OVERLAY)
                }
              }
            />
          </Fragment>
        ))}

        {popups.map((modal) => (
          <Fragment key={modal.modal.id}>
            <modal.modal.Component
              {...modal.modal.defaultProps}
              {...modal.props}

              isShown={modal.isVisible}
              setIsShown={(val: boolean, options?: OptionsType) => {
                if(!val) {
                  modalManager.close(modal.modal.id, options)
                  if(modal.config?.onClose) {
                    modal.config.onClose()
                  }
                }
              }}
              onBack={globalModalsHistory[ModalType.POPUP].length <= 1
                ? undefined
                : () => {
                  if(modal?.config?.onBack) {
                    modal.config.onBack()
                  }

                  goBack(ModalType.POPUP)
                }
              }
              transition={globalModalsHistory[ModalType.OVERLAY].length < 2}
            />
          </Fragment>
        ))}
      </div>
    </FocusLock>
  )
})
