import { db, DEXIE_STORES } from '@/database'
import { ProfileModelFactory } from '@/models/Profile.model'
import { addOrUpdateProfiles } from '@/repositories/profiles.repository'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { ProfileModel } from '@/types/models/profile'
import { persist, retrieveClientside } from '@roolz/sdk/utils/auth'
import { RecursivePartial } from '@roolz/types'
import { Company } from '@roolz/types/api/companies'
import { Offer } from '@roolz/types/api/exchange'
import { Device, Profile, ProfileRole } from '@roolz/types/api/profiles'
import { ClientProfileInfo } from '@roolz/types/database'
import { liveQuery } from 'dexie'
import { merge, mergeWith } from 'lodash-es'
import { makeAutoObservable } from 'mobx'

class ProfilesStore {
  my_profile_id?: Profile['id']
  my_device?: Device

  profiles: Record<Profile['id'], ProfileModel> = {}
  presenceSubscriptionQueue: Array<Profile['id']> = []

  clientProfileInfos: ClientProfileInfo[] = []

  constructor() {
    makeAutoObservable(this)
  }

  get activeCompanyId(): Company['id'] | null {
    const company = myCompaniesStore.companies.find(({ id }) => id === this.my_profile?.active_space_company_id)

    return company?.id ?? null
  }

  get bannedProfiles() {
    return Object.values(this.profiles).filter(profile => profile.relationships?.is_banned)
  }

  get my_profile() {
    return this.my_profile_id ? this.profiles[this.my_profile_id] : null
  }

  /**
   * If required fields of profile filled, or show the Profile Fill page to fill them
   */
  get isMyProfileFilled(): boolean {
    return !!this.my_profile?.is_filled
  }

  addOrUpdateProfile = (profile: Profile) => {
    if(this.updateProfileIfExists(profile)) {
      return
    }

    if(profile?.id === this.my_profile_id) {
      retrieveClientside().then((credentials: any) => {
        if(credentials && profile.lang_code && credentials?.lang_code !== profile.lang_code) {
          persist({ ...credentials, lang_code: profile.lang_code })
        }
      }).catch(console.log)
    }

    this.presenceSubscriptionQueue.push(profile.id)
    profilesService.bindToProfilesOnlineChanges()
    liveQuery(() => db[DEXIE_STORES.PROFILES].get(profile.id))
      .subscribe(profile => {
        profile && this.updateProfileIfExists(profile)
      })

    const model = ProfileModelFactory(profile)
    this.profiles[model.id] = model

    return model
  }

  updateProfileIfExists = (profile: Profile) => {
    const existing = this.profiles[profile.id]

    if(existing) {
      mergeWith(existing, profile, (a: unknown, b: unknown) => {
        if(Array.isArray(b)) return b
      })

      return true
    }

    return false
  }
  //
  // setMyProfile(profile: Profile) {
  //   this.addOrUpdateProfile(profile)
  //
  //   retrieveClientside().then((credentials: any) => {
  //     if(credentials && profile.lang_code && credentials?.lang_code !== profile.lang_code) {
  //       persist({ ...credentials, lang_code: profile.lang_code })
  //     }
  //   }).catch(console.log)
  //
  //   this.addOrUpdateProfile(profile)
  //
  //   // this.addOrUpdateProfile(profile)
  //
  //   this.my_profile = ProfileModelFactory(profile)
  // }

  setMyDevice(device: Device) {
    this.my_device = device
  }

  findProfile = (id: Profile['id']): ProfileModel | undefined => {
    if(id == this.my_profile?.id) {
      return this.my_profile
    }

    return this.profiles[id]
  }

  patchProfile(id: Profile['id'], data: RecursivePartial<Profile>) {
    const profile = this.findProfile(id)

    if(profile) {
      merge(profile, data)
    }
  }

  hasAccessToOffer(offer: Offer): boolean {
    if(!this.my_profile) return false
    if([ProfileRole.Admin, ProfileRole.Moderator].includes(this.my_profile.role)) {
      return true
    }

    if(!offer.company_id) {
      return this.activeCompanyId ? false : this.my_profile?.id === offer.creation_profile_id
    } else {
      return this.activeCompanyId === offer.company_id
    }
  }

  canBanProfile(profile: ProfileModel): boolean {
    return !!profile?.relationships && !profile.relationships?.is_colleague && !profile.relationships?.is_banned
  }

  canUnbanProfile(profile: ProfileModel): boolean {
    return !!profile?.relationships && !profile.relationships.is_colleague && profile.relationships?.is_banned
  }

  canAddToPersonalContacts(profile: ProfileModel): boolean {
    return !!profile?.relationships
      && !profile.relationships.is_personal_contact
      && (profile.relationships.is_colleague || !profile.relationships.is_banned_me)
  }

  canRemoveFromPersonalContacts(profile: ProfileModel): boolean {
    return !!profile?.relationships && profile.relationships.is_personal_contact
  }

  getClientInfo(profileId: Profile['id']) {
    return this.clientProfileInfos.find(({ id }) => id === profileId)
  }

  addOrUpdateClientProfileInfo(info: ClientProfileInfo) {
    const existing = this.clientProfileInfos.find(({ id }) => id === info.id)

    if(existing) {
      return merge(existing, info)
    }

    this.clientProfileInfos.push(info)
  }
}

const profilesStore = new ProfilesStore()

export {
  ProfilesStore,
  profilesStore
}
