import { Message, Chat, MessageState, MessageStatus, OwnPcp } from '../api/chats'
import { Device, Profile } from '../api/profiles'

export enum OutgoingPackageType {
  NewMessage = 'message.new',
  MessageStatus = 'message.status',
  Presence = 'system.chat.user',
  Online = 'system.user.event'
}

export type NewMessagePackage = Message

export interface DeleteMessagePackage {
  chat_id: Chat["id"]
  deleted_messages_state: {
    [key: Message["number"]]: MessageState
  }
}

export enum ChatUserEventTypes {
  Typing = 1,
  Recording = 2,
  SendingFile = 3
}

export interface ChatUserEventPackage {
  event: ChatUserEventTypes

  chat_id: Chat["id"]
  user_id: Profile["id"]
  user_name: Profile["nickname"]
}

export interface SendMessageErrorPackage {
  message: Partial<Message>
  error: "permission_error" | unknown
}

export interface MessageStatusChangePackage {
  chat_id: Chat["id"]
  client_message_id: Message["client_message_id"]
  message_number: Message["number"]
  message_status: MessageStatus
  sender_id: Message["sender_id"]
}

export type ChatUpdatePackage = Partial<OwnPcp>

export type ChatModelUpdatePackage = Chat

export enum IncomingPackageType {
  NewMessage = 'message.new',
  UpdateMessage = 'message.update',
  SendMessageError = 'worker.send_message.error',
  MessageStatusChange = 'message.status',
  DeleteMessage = 'messages.delete',
  ChatUserEvent = 'system.chat_user.event',
  UserEvent = 'system.user.event',
  // It's not chat, but own pcp update package
  UpdateChat = 'chat.update',
  ChatModelUpdate = 'chat.model_update',

  ProfileUpdated = 'profile.updated',
  ProfileBanned = 'profile.contact.banned',
  ProfileUnbanned = 'profile.contact.unbanned',
  ProfileDeleted = 'profile.deleted',

  CompanyMemberDeleted = 'company.profile.deleted',
}

export type UserEventPackage = OnlinePackage | any // TODO add more

export function isOnlinePackage(data: UserEventPackage) {
  return data?.event === 1 // TODO replace by enum
}

export interface OnlinePackage {
  "roolz-is-background": 0 | 1
  state_radio_map: boolean
  event: 1 // TODO ???
  os_type: Device["os_type"]
  device_id: Device["id"]
  state_radio_chats: boolean
  user_id: Profile["id"]
}
