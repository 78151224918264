import { exchangeService } from '@/store/exchange/exchange.service'
import { exchangeStore } from '@/store/exchange/exchange.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ExtraOfferStatus, Offer, OfferInStatusCount, OfferStatus } from '@roolz/types/api/exchange'
import { makeAutoObservable, toJS } from 'mobx'
import dayjs from '@roolz/sdk/plugins/dayjs'

export const ITEMS_PER_PAGE = 20

const iterableStatusMap = {
  [ExtraOfferStatus.OPENED]: [OfferStatus.PUBLISHED, OfferStatus.NOT_PUBLISHED, OfferStatus.AWAIT_CONFIRM, OfferStatus.CONFIRMATION],
  [OfferStatus.NOT_PUBLISHED]: [OfferStatus.NOT_PUBLISHED],
  [OfferStatus.PUBLISHED]: [OfferStatus.PUBLISHED],
  [OfferStatus.AWAIT_CONFIRM]: [OfferStatus.AWAIT_CONFIRM],
  [OfferStatus.CONFIRMATION]: [OfferStatus.CONFIRMATION],
  [OfferStatus.CLOSED]: [OfferStatus.DEAL_MADE, OfferStatus.CLOSED]
}

export const myOffersStoreStatuses = (Object.keys(iterableStatusMap) as unknown as (keyof typeof iterableStatusMap)[])

export class MyOffersStore {
  [ExtraOfferStatus.OPENED]: OffersStatusStore
  [OfferStatus.NOT_PUBLISHED]: OffersStatusStore
  [OfferStatus.PUBLISHED]: OffersStatusStore
  [OfferStatus.AWAIT_CONFIRM]: OffersStatusStore
  [OfferStatus.CONFIRMATION]: OffersStatusStore
  [OfferStatus.CLOSED]: OffersStatusStore

  constructor() {
    makeAutoObservable(this)

    myOffersStoreStatuses.forEach(status => this[status] = new OffersStatusStore(this, iterableStatusMap[status]))
  }

  get isEmptyOpenedOffers() {
    return (
      !this[OfferStatus.PUBLISHED].total &&
      !this[OfferStatus.NOT_PUBLISHED].total &&
      !this[OfferStatus.CONFIRMATION].total &&
      !this[OfferStatus.AWAIT_CONFIRM].total
    )
  }

  addOfferByStatus = (offer: Offer) => {
    myOffersStoreStatuses
      .filter(status => iterableStatusMap[status].includes(offer.status))
      .forEach(status => this[status].addOffer(offer._id))
  }

  removeOfferByPrevStatus = (offer: Offer) => {
    myOffersStoreStatuses
      .filter(status => offer.prev_status ? iterableStatusMap[status].includes(offer.prev_status) : false)
      .forEach(status => this[status].removeOffer(offer._id))
  }

  updateOffer(offer: Offer) {
    if(!profilesStore.hasAccessToOffer(offer)) return

    this.removeOfferByPrevStatus(offer)
    this.addOfferByStatus(offer)
  }

  reset(withTotal = true) {
    myOffersStoreStatuses.forEach(status => this[status].reset(withTotal))
  }
}

export enum SortOffersBy {
  UPDATED_DATE = 'updated_at',
  CREATED_DATE = 'created_at'
}

export class OffersStatusStore {
  isLoadedOnce = false
  reachedEnd = false
  stateId = Math.random()

  total = 0
  offers: Offer['_id'][] = []
  loading = false

  sortBy: SortOffersBy = SortOffersBy.UPDATED_DATE
  statuses: OfferStatus[]
  rootStore: MyOffersStore


  constructor(rootStore: MyOffersStore, statuses: OfferStatus[]) {
    makeAutoObservable(this)

    this.rootStore = rootStore
    this.statuses = statuses
  }

  get items() {
    return this.offers
      .map(exchangeStore.findOffer)
      .filter((offer): offer is Offer => !!offer)
      .filter(offer => !offer.deleted_at)
      .sort((a, b) => dayjs(b.updated_at).unix() - dayjs(a.updated_at).unix())
  }

  get itemsLeft() {
    if(this.reachedEnd) {
      return 0
    }

    return Math.max(0, this.total - this.offers.length)
  }

  addOffer = (id: Offer['_id']) => {
    if(this.offers.includes(id)) return

    this.total += 1
    this.offers.unshift(id)
  }

  removeOffer = (id: Offer['_id']) => {
    this.total -= 1
    this.offers = this.offers.filter(_id => _id !== id)
  }

  async loadMore() {
    this.loading = true

    const currentStateId = this.stateId
    const offset = this.offers.length

    if(this.isLoadedOnce && offset >= this.total) {
      console.groupCollapsed('%c Trying to load offers exceeding total count', 'color: orange')
      console.log('store statuses: ', toJS(this.statuses))
      console.log('total: ', this.total)
      console.log('offset: ', offset)
      console.groupEnd()
    }

    try {
      const { result, count_by_status, total } = await exchangeService.loadMyOffers({
        offset,
        limit: ITEMS_PER_PAGE,
        status: this.statuses
      })

      if(this.stateId !== currentStateId) {
        console.groupCollapsed('%c Offers loading result is stale', 'color: orange')
        console.log('store statuses: ', toJS(this.statuses))
        console.log('current state id: ', currentStateId)
        console.log('store state id: ', this.stateId)
        console.groupEnd()
        return
      }

      this.isLoadedOnce = true
      this.total = total
      this.offers = [...new Set([...this.offers, ...result.map(item => item._id)])]

      count_by_status.forEach(({ status, count }) => this.rootStore[status] && (this.rootStore[status].total = count))

      if(result.length < ITEMS_PER_PAGE) {
        this.reachedEnd = true
      }

      this.loading = false
    } catch(e) {
      if(this.stateId !== currentStateId) {
        console.log('Offers loading result is stale')
        return
      }
      this.loading = false

      throw e
    }
  }

  reset(withTotal = true) {
    this.isLoadedOnce = false
    this.reachedEnd = false
    withTotal && (this.total = 0)
    this.offers = []
    this.loading = false

    this.stateId = Math.random()
  }
}

export const myOffersStore = new MyOffersStore()

// const enableReactionForOffersStore = (status: ExtraOfferStatus | OfferStatus, color: string) => {
//   reaction(() => [
//     myOffersStore[status].total,
//     myOffersStore[status].itemsLeft
//   ], (value) => {
//     console.group(`%c ${status}`, `color: ${color}`)
//     console.log(`${status} TOTAL: `, value[0])
//     console.log(`${status} ITEMS LEFT: `, value[1])
//     console.log(`${status} ITEMS: `, myOffersStore[status].offers.length)
//     console.log(`${status} GAP: `, myOffersStore[status].gap)
//     console.groupEnd()
//   })
// }
//
// enableReactionForOffersStore(OfferStatus.NOT_PUBLISHED, colorByTypeWithStatus.offer.status[OfferStatus.NOT_PUBLISHED])
// enableReactionForOffersStore(OfferStatus.PUBLISHED, colorByTypeWithStatus.offer.status[OfferStatus.PUBLISHED])
// enableReactionForOffersStore(OfferStatus.AWAIT_CONFIRM, colorByTypeWithStatus.offer.status[OfferStatus.AWAIT_CONFIRM])
// enableReactionForOffersStore(OfferStatus.CONFIRMATION, colorByTypeWithStatus.offer.status[OfferStatus.CONFIRMATION])
