import { api } from '@/api'
import { companiesService } from '@/store/companies/companies.service'
import { toastError } from '@roolz/sdk/components/snackbars'
import { EmailItem, EmailMultiSelect } from '@/components/ui/fields/EmailMultiSelect/EmailMultiSelect'
import { Select } from '@roolz/sdk/components/ui/fields/Select/Select'
import { Loadable } from '@/components/ui/Loadable/Loadable'
import { Label } from '@/components/ui/typography/Label/Label'
import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { Button } from '@mui/material'
import { VALIDATION_STATUS_CODES } from '@roolz/types/custom'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styles from '@/components/company-admin/forms/InviteMembersForm/InviteMembersForm.module.scss'

export const STATUSES = {
  LIMIT: 'limit',
  INCORRECT: 'incorrect',
  DUPLICATE: 'duplicate',
  ACCEPT: 'accept',
  DELETE: 'delete'
}

interface Form {
  role: string
  emails: string[]
  profile_ids: string[]
}

const defaultValues: Form = {
  role: 'member',
  emails: [],
  profile_ids: []
}

type InviteMembersFormProps = {
  cancelText?: string
  acceptText?: string
  hideModal?: () => void
  reloadMembers?: () => void | Promise<void>
  companyId: string
}

const getEmailItem = (email: string, status: string): EmailItem => {
  const item: EmailItem = { value: email, error: null, variant: 'error' }

  if([STATUSES.DELETE, STATUSES.LIMIT, STATUSES.INCORRECT].includes(status)) {
    return { ...item, error: status }
  }

  if(status === STATUSES.DUPLICATE) {
    return {
      value: email,
      error: status,
      variant: 'warning'
    }
  }

  return {
    value: email,
    error: null
  }
}

const NO_CONTENT_STATUS = 204

export const InviteMembersForm = ({
  hideModal,
  reloadMembers,
  cancelText,
  acceptText,
  companyId
}: InviteMembersFormProps) => {
  const { t } = useTranslation('company/users')
  const { t: errorsT } = useTranslation('errors')
  const navigate = useNavigate()
  const ref = useRef<{ parseAndAddEmails: () => Promise<boolean> }>(null)

  const accept = acceptText || t('invite')
  const cancel = cancelText || t('cancel')

  const [loading, setLoading] = useState<boolean>(false)

  const validate = async (email: string) => {
    // return getEmailItem(email, STATUSES.ACCEPT)
    try {
      await api.companies.checkEmailInCompany(companyId, { email })

      return getEmailItem(email, STATUSES.ACCEPT)
    } catch(e: any) {
      const code = e?.response?.data?.error_code

      switch(code) {
        case VALIDATION_STATUS_CODES.INVALID_EMAIL:
          return getEmailItem(email, STATUSES.INCORRECT)
        case VALIDATION_STATUS_CODES.SPACE_LIMIT_EXCEEDED:
          return getEmailItem(email, STATUSES.LIMIT)
        case VALIDATION_STATUS_CODES.ENTITY_ALREADY_EXISTS:
          return getEmailItem(email, STATUSES.DUPLICATE)
        default:
          navigate(resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE))
          return null

      }
    }
  }

  const form = useForm<Form>({ mode: 'onChange', defaultValues })

  const onEmailChange = useCallback((value: string[]) => {
    form.setValue('emails', value)
  }, [])

  const roles = useMemo(() => [{
    value: 'member',
    label: t('roles.member')
  }, {
    value: 'admin',
    label: t('roles.admin')
  }], [])

  const onSubmit = async () => {
    if(!companyId || loading) return

    setLoading(true)
    if(!form.getValues().emails.length) {
      const isValid = await ref.current?.parseAndAddEmails()
      if(!isValid) {
        return setLoading(false)
      }
    }

    const {
      role,
      profile_ids,
      emails
    } = form.getValues()

    try {
      await companiesService.sendCompanyInvitation(companyId, {
        role,
        profile_ids,
        emails
      })

      hideModal?.()
      await reloadMembers?.()

    } catch(e: any) {
      toastError(e?.response?.data?.error_msg ?? errorsT('insufficient_request'))
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <div className={styles.formBlock}>
        <div className={styles.formGroup}>
          <Label
            htmlFor='role'
            required
          >
            {t('role')}
          </Label>
          <Controller
            control={form.control}
            name='role'
            render={({ field }) => (
              <Select
                id='role'
                fullWidth
                items={roles}
                allowEmpty={false}
                placeholder={t('member')}
                {...field}
              />
            )}
          />
        </div>
      </div>

      <EmailMultiSelect
        onChange={onEmailChange}
        validate={validate}
        ref={ref}
      />

      <div className={styles.actions}>
        <Button
          tabIndex={1}
          onClick={hideModal}
        >
          {cancel}
        </Button>
        <Loadable
          loading={loading}
          delay={500}
        >
          <Button
            tabIndex={2}
            onClick={onSubmit}
            disabled={loading}
          >
            {accept}
          </Button>
        </Loadable>
      </div>
    </>
  )
}
