import layoutConfig from '@/assets/scss/config.module.scss'
import stylesConfig from '@/assets/scss/config.module.scss'
import { ProfileAvatar } from '@/components/profile/ui/ProfileAvatar'
import Button from '@/components/ui/buttons/Button/Button'
import { HeaderButton } from '@/components/ui/buttons/HeaderButton/HeaderButton'
import { CountryFlag } from '@/components/ui/CountryFlag/CountryFlag'
import { Breakpoint } from '@/components/utils/Breakpoint'
import { MAX_COUNT_COMPANIES } from '@/config/const'
import { fireClickButtonCreateOffer, fireCompanyCreateButton, fireEnterCompanyAdmin } from '@/config/events'
import { LOCALE_FLAGS } from '@/config/locales'
import { resolvePathByName, ROUTE_NAMES, useNavigateToModal } from '@/config/routes'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import i18n, { LOCALES } from '@/plugins/i18n'
import { authService } from '@/store/auth/auth.service'
import { companiesStore } from '@/store/companies/companies.store'
import { companyStore } from '@/store/companies/company.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { knowledgeService } from '@/store/knowledge/knowledge.service'
import { partnershipStore } from '@/store/partnership/partnership.store'
import { profilesService } from '@/store/profiles/profiles.service'
import { partnershipService } from '@/store/partnership/partnership.service'
import { profilesStore } from '@/store/profiles/profiles.store'
import { systemStore } from '@/store/system/system.store'
import { uiStore } from '@/store/ui/ui.store'
import { getNow } from '@/utils/date'
import { Popper } from '@mui/material'
import { Add } from '@roolz/icons/Add'
import { Close } from '@roolz/icons/Close'
import { EmptyAvaRec } from '@roolz/icons/EmptyAvaRec'
import { Group } from '@roolz/icons/Group'
import { Help } from '@roolz/icons/Help'
import { Inventory } from '@roolz/icons/Inventory'
import { LocalShipping } from '@roolz/icons/LocalShipping'
import { LockOpen } from '@roolz/icons/LockOpen'
import { Logout } from '@roolz/icons/Logout'
import { Rocket } from '@roolz/icons/lottie/Rocket'
import { NoAccounts } from '@roolz/icons/NoAccounts'
import { ProfileCircle } from '@roolz/icons/ProfileCircle'
import { Settings } from '@roolz/icons/Settings'
import { InstagramIcon } from '@roolz/icons/socials/InstagramIcon'
import { TikTokIcon } from '@roolz/icons/socials/TikTokIcon'
import { UnknownDocument } from '@roolz/icons/UnknownDocument'
import { Avatar } from '@roolz/sdk/components/ui/Avatar/Avatar'
import { ExpandButton } from '@roolz/sdk/components/ui/buttons/ExpandButton/ExpandButton'
import { MenuDescription } from '@roolz/sdk/components/ui/MenuList/MenuDescription/MenuDescription'
import { MenuList } from '@roolz/sdk/components/ui/MenuList/MenuList'
import { MenuListContent } from '@roolz/sdk/components/ui/MenuList/MenuListContent/MenuListContent'
import { MenuListItem } from '@roolz/sdk/components/ui/MenuList/MenuListItem/MenuListItem'
import { MenuListItemGroup } from '@roolz/sdk/components/ui/MenuList/MenuListItemGroup/MenuListItemGroup'
import { removeAfterAuthURL } from '@roolz/sdk/utils/afterAuthRedirect'
import { UCFirst } from '@roolz/sdk/utils/formatting'
import { Company, CompanyRoles } from '@roolz/types/api/companies'
import cn from 'classnames'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'
import { MouseEvent, ReactNode, Ref, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import styles from './Header.module.scss'

const getSocialLinks = (lang: string) => {
  const isCIS = ['ru', 'uk'].includes(lang.replace(/-.*$/, ''))

  return [
    { link: `https://www.tiktok.com/@roolz.net_${isCIS ? 'ru' : 'logistics'}`, icon: <TikTokIcon size={12}/> },
    { link: `https://www.instagram.com/roolz.net_${isCIS ? 'ru' : 'logistics'}`, icon: <InstagramIcon size={14}/> }
  ]
}

const DONT_SHOW_ADVICE_STORAGE_KEY = 'dont-show-advice'
const DONT_SHOW_ADVICE_STORAGE_VALUE = '1'

const Header = observer(function Header() {
  const { i18n } = useTranslation('layout')
  const { t: tUI } = useTranslation('ui')
  const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false)

  const userIconRef = useRef<any>()
  const languageIconRef = useRef<any>()

  const profile = profilesStore.my_profile
  const mobileSidebarBreakpoint = Number.parseFloat(stylesConfig.mobileSidebarBreakpointWidth)

  const handleOpenHelp = () => {
    // @ts-ignore
    jivo_api.open()
  }

  const handleLogoClick = (e: MouseEvent): void => {
    e.preventDefault()
  }

  const openGuideSite = () => {
    const link = ['ru', 'uk'].includes(i18n.language.replace(/-.*$/, ''))
      ? 'https://help.roolz.net/ru-RU/support/home'
      : 'https://help.roolz.net/en/support/home'

    window.open(link, '_blank')
  }

  const handleLanguageSelectToggle = (isOpen: boolean) => {
    setIsLanguageSelectOpen(isOpen)
  }

  return (<>
    {uiStore.isSidebarMobileOpen && (
      <div className={styles.headerBackdrop}/>
    )}

    <div
      className={cn(styles.header, {
        [styles.headerFixed]: uiStore.isSidebarMobileOpen
      })}
    >
      {/*<div className={styles.group}>*/}
      <Breakpoint minWidth={mobileSidebarBreakpoint}>
        <Link
          className={styles.logoWrapper}
          onClick={handleLogoClick}
          to={resolvePathByName(ROUTE_NAMES.HOME)}
        >
          <img src={process.env.REACT_APP_CDN_URL + '/web/logo/logo-letter.svg'} alt=''/>
        </Link>
      </Breakpoint>

      <Breakpoint maxWidth={Number.parseFloat(layoutConfig.mobileSidebarBreakpointWidth)}>
        <Hamburger
          onClick={() => uiStore.toggleSidebarMobileOpen()}
          active={uiStore.isSidebarMobileOpen}
        />
      </Breakpoint>

      {profilesStore.isMyProfileFilled && (
        <div className={styles.actions}>
          {profilesStore.isMyProfileFilled && (
            <Breakpoint maxWidth={mobileSidebarBreakpoint}>
              <CompanyMenu mobileSidebarBreakpoint={mobileSidebarBreakpoint}/>
            </Breakpoint>
          )}
          {!uiStore.isSidebarMobileOpen && (
            <CreateOfferButton/>
          )}

          <Breakpoint minWidth={mobileSidebarBreakpoint}>
            <HeaderButton
              title={tUI('guide_button.title')}
              appeal={tUI('guide_button.appeal')}
              icon={<UnknownDocument/>}
              onClick={openGuideSite}
            />
          </Breakpoint>

          {systemStore.isJivositeLoaded && (
            <Breakpoint minWidth={mobileSidebarBreakpoint}>
              <HeaderButton
                title={tUI('support_button.title')}
                appeal={tUI('support_button.appeal')}
                icon={<Help/>}
                onClick={handleOpenHelp}
              />
            </Breakpoint>
          )}
        </div>
      )}
      {/*</div>*/}

      {/*<div className={styles.group}>*/}
      <Breakpoint
        minWidth={mobileSidebarBreakpoint}
        forceView={uiStore.isSidebarMobileOpen}
      >
        {getSocialLinks(i18n.language).map(({ link, icon }, i) => (
          <a key={i} className={styles.socialLink} href={link} target='_blank'>
            {icon}
          </a>
        ))}
        <button
          className={styles.lang}
          ref={languageIconRef}
        >
          {i18n.language.replace(/-.*/, '').toUpperCase()}
        </button>

        <LanguageMenu anchorRef={languageIconRef} onOpenChange={handleLanguageSelectToggle}/>

        <Breakpoint minWidth={mobileSidebarBreakpoint}>
          {profilesStore.isMyProfileFilled && (
            <div className={styles.actions}>
              <CompanyMenu
                mobileSidebarBreakpoint={mobileSidebarBreakpoint}
                isLanguageSelectOpen={isLanguageSelectOpen}
              />
            </div>
          )}
        </Breakpoint>
      </Breakpoint>

      {!uiStore.isSidebarMobileOpen && profile && (<>
        <button
          className={styles.user}
          ref={userIconRef}
        >
          <ProfileAvatar
            profile={profile}
            className={styles.avatar}
            width={40}
          />
        </button>


        <UserMenu anchorRef={userIconRef}/>
      </>)}
      {/*</div>*/}
    </div>
  </>)
})

export default Header

const Hamburger = ({
  onClick,
  active
}: {
  onClick: () => void
  active: boolean
}) => {
  return (
    <button
      className={cn(styles.hamburger, {
        [styles.hamburgerActive]: active
      })}
      onClick={onClick}
    >
      <div className={styles.hamburger__line}/>
      <div className={styles.hamburger__line}/>
      <div className={styles.hamburger__line}/>
    </button>
  )
}

const LanguageMenu = ({
  anchorRef,
  onOpenChange
}: {
  anchorRef: any
  onOpenChange: (isOpen: boolean) => void
}) => {
  const { i18n } = useTranslation()

  function setLang(lang: string) {
    knowledgeService.cleanAllDictionaries()
    i18n.changeLanguage(lang)

    // @ts-ignore
    window.showLoader()

    profilesService.updateMyProfile({
      lang_code: lang.toLowerCase()
    })
      .finally(() => {
        window.location.reload()
      })
  }

  function getLocaleItem(lang: string) {
    return <>
      {/*{localeFlag(lang)}*/}
      {lang.replace(/-.*/, '').toUpperCase()}
    </>
  }

  function localeFlag(locale: string) {
    locale = locale.replace(/-.*/, '')
    // console.log(locale, LOCALE_FLAGS[locale])
    return LOCALE_FLAGS[locale]
      ? <CountryFlag alpha2={LOCALE_FLAGS[locale]} width={16}/>
      : ''
  }

  return (
    <MenuList
      onOpenChange={onOpenChange}
      className={styles.lang__menu}
      anchorRef={anchorRef}
      placement='bottom'
      offset={[0, -49]}
      keepMounted={true}
    >
      <MenuListContent className={styles.lang__menu__content}>
        <MenuListItem
          lang={i18n.language}
          className={cn(styles.lang__menu__item, styles.lang__menu__item__active)}
          label={getLocaleItem(i18n.language)}
          onClick={() => {
          }}
          to={''}
          prepend={localeFlag(i18n.language)}
        />
        {Object.values(LOCALES)
          .map((locale) => locale.toLowerCase())
          .filter(lang => lang !== i18n.language)
          .map(lang => (
            <MenuListItem
              key={lang}
              className={styles.lang__menu__item}
              label={getLocaleItem(lang)}
              onClick={() => setLang(lang)}
              prepend={localeFlag(lang)}
            />
          ))}
      </MenuListContent>
    </MenuList>
  )
}

const UserMenu = observer(function UserMenu({ anchorRef }: {
  anchorRef: Ref<any>
}) {
  const { t } = useTranslation('layout')

  const globalModals = useGlobalModals()
  const navigate = useNavigateToModal()

  async function handleLogout() {
    // @ts-ignore
    window.showLoader()
    removeAfterAuthURL()
    try {
      await authService.fullLogout()
    } finally {
      const redirectUrl = process.env.REACT_APP_ROOLZ_STATIC_URL + '/' + i18n.language + window.location.pathname

      window.location.href = redirectUrl
    }
  }

  return (
    <MenuList
      anchorRef={anchorRef}
      placement='bottom-end'
      offset={[-15, -3]}
      className={styles.menu}
      keepMounted={true}
    >
      <MenuListContent>
        {profilesStore.isMyProfileFilled && (<>
          <MenuLink
            prepend={<ProfileCircle/>}
            label={t('header.nav.my_profile')}
            to={resolvePathByName(ROUTE_NAMES.MY_PROFILE)}
          />
          <MenuListItem
            onClick={() => {
              navigate(resolvePathByName(ROUTE_NAMES.CONTACT_LIST))
            }}
            className={styles.navLink}
            Component='button'
            prepend={<Group/>}
            label={t('header.nav.contacts')}
          />
          <MenuListItemGroup
            prepend={<Settings/>}
            label={t('header.nav.settings')}
          >
            <MenuLink
              prepend={<LockOpen/>}
              label={t('header.nav.auth_methods')}
              to={resolvePathByName(ROUTE_NAMES.MY_PROFILE_AUTH_METHODS)}
            />
            <MenuLink
              prepend={<NoAccounts/>}
              label={t('header.nav.black_list')}
              to={resolvePathByName(ROUTE_NAMES.BLACK_LIST)}
            />
          </MenuListItemGroup>
        </>)}
        <MenuLink
          prepend={<Logout/>}
          label={t('header.nav.logout')}
          // to={resolvePathByName(ROUTE_NAMES.LOGOUT)}
          onClick={handleLogout}
          labelClassName={styles.logout}
        />
      </MenuListContent>
    </MenuList>
  )
})

function MenuLink({
  prepend,
  label,
  to,
  ...rest
}: {
  prepend?: ReactNode,
  label: ReactNode,
  to?: string,
  [key: string]: any
}) {
  return (
    <MenuListItem
      className={styles.navLink}
      Component={to?.length ? Link : undefined}
      to={to}
      prepend={prepend}
      label={label}
      {...rest}
    />
  )
}

const Prepend = ({
  company
}: {
  company: Company
}) => {
  return (
    <Avatar
      className={styles.avatarHeader}
      avatarUrl={company.logo_path ?? ''}
      first_name={company.name ?? ''}
      color_code={company.color ?? ''}
      width={24}
      type='company'
    />
  )
}

const CompanyMenu = observer(function CompanyMenu({
  mobileSidebarBreakpoint,
  isLanguageSelectOpen
}: {
  mobileSidebarBreakpoint: number
  isLanguageSelectOpen?: boolean
}) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isAdviceOpen, setIsAdviceOpen] = useState<boolean>(false)

  const profile = profilesStore.my_profile
  const navigate = useNavigate()
  const location = useLocation()

  const globalModals = useGlobalModals()

  const { t } = useTranslation('layout')
  const buttonRef = useRef<any>()
  const manageRef = useRef<any>()

  const activeSpaceCompanyId = profilesStore.my_profile?.active_space_company_id
  // null - personal space
  // undefined - not personal space, but company model is not loaded yet
  const activeCompany = companiesStore.activeCompany

  useEffect(() => {
    if(
      dayjs(getNow()).diff(profile?.created_at, 'days') < 14
      && activeCompany
      && doCanManage(activeCompany)
      && localStorage.getItem(DONT_SHOW_ADVICE_STORAGE_KEY) !== DONT_SHOW_ADVICE_STORAGE_VALUE
    ) {
      setIsAdviceOpen(true)
    }
  }, [myCompaniesStore.companies, profile, activeCompany])

  useLayoutEffect(() => {
    if(isAdviceOpen && (isMenuOpen || isLanguageSelectOpen)) {
      handleCloseAdvice()
    }
  }, [isMenuOpen, isAdviceOpen, isLanguageSelectOpen])

  const handleCloseAdvice = () => {
    setIsAdviceOpen(false)
    localStorage.setItem(DONT_SHOW_ADVICE_STORAGE_KEY, DONT_SHOW_ADVICE_STORAGE_VALUE)
  }

  const chooseCompany = (company: Company | null) => {
    // @ts-ignore
    window.showLoader()

    profilesService.updateMyProfile({
      active_space_company_id: company?.id ?? null
    })
    .then(res => {
      // window.history.pushState({}, '', resolvePathByName(ROUTE_NAMES.HOME))

      window.location.reload()
    })
  }

  function openCreateCompany() {
    fireCompanyCreateButton()


    // uiStore.isSidebarMobileOpen = false
    navigate(resolvePathByName(ROUTE_NAMES.COMPANY_CREATE), {state: { prevLocation: location }})
  }

  function getCompanySettingsUrl(company: Company) {
    return resolvePathByName(ROUTE_NAMES.COMPANY_ADMIN_MAIN)
      .replace(':company_id', company?.id ?? '')
  }

  const handleOpenSettings = (company: Company): void => {
    companyStore.lastAppLocation = location
    fireEnterCompanyAdmin()

    navigate(getCompanySettingsUrl(company))
  }

  function doCanManage(company: Company) {
    return !!company.my_role && [CompanyRoles.admin, CompanyRoles.owner].includes(company.my_role)
  }

  if(!profile) {
    return null
  }

  return (
    <Breakpoint minWidth={mobileSidebarBreakpoint} forceView={uiStore.isSidebarMobileOpen}>
      <div className={styles.companySelect__desktop}>
        {!myCompaniesStore.companies.length
          ? (
            <Button
              variant='outlined'
              onClick={openCreateCompany}
            >
              <Add color='#4778EE' className={styles.button__icon}/>
              {t('header.create_company')}
            </Button>
          ) : (
            <>
              <div ref={buttonRef} className={styles.companyMenu__open}>
                {activeCompany
                  ? <Prepend company={activeCompany}/>
                  : <EmptyAvaRec size={24}/>
                }
                <div className={styles.companyMenu__open__info}>
                <span className={styles.companyMenu__open__name}>
                  {activeCompany?.name ? UCFirst(activeCompany?.name) : t('header.self_space')}
                </span>

                  {activeCompany && doCanManage(activeCompany) && (
                    <>
                      <button
                        ref={manageRef}
                        className={styles.companyMenu__open__manage}
                        onClick={() => handleOpenSettings(activeCompany)}
                      >
                        {t('header.manage_company')}
                      </button>

                      {isAdviceOpen && (
                        <Breakpoint minWidth={mobileSidebarBreakpoint}>
                          <AdviceTooltip
                            anchorRef={manageRef}
                            open={isAdviceOpen}
                            onClose={handleCloseAdvice}
                          />
                        </Breakpoint>
                      )}
                    </>
                  )}
                </div>

                <ExpandButton size={8} expanded={isMenuOpen} />
              </div>
              <MenuList
                className={cn(styles.createOffer__menu, styles.companyMenu__list)}
                anchorRef={buttonRef}
                placement='bottom'
                offset={[2, 0]}
                keepMounted={true}
                onOpenChange={setIsMenuOpen}
              >
                <MenuListContent className={styles.companyMenu__content}>
                  {/*{activeCompany*/}
                  {/*  ? (*/}
                  {/*    <SpaceLabel*/}
                  {/*      company={activeCompany}*/}
                  {/*      isActive*/}
                  {/*    />*/}
                  {/*  ) : (*/}
                  {/*    <SpaceLabel*/}
                  {/*      company={null}*/}
                  {/*      onClick={() => chooseCompany(null)}*/}
                  {/*    />*/}
                  {/*  )*/}
                  {/*}*/}
                  <MenuDescription
                    label={t('header.select_company')}
                    className={styles.companyMenu__description}
                    labelClassName={styles.companyMenu__description__label}
                  />

                  {myCompaniesStore.companies
                    .slice(0, MAX_COUNT_COMPANIES)
                    .filter(company => company.id !== activeCompany?.id)
                    .map(company => company.id &&
                      <SpaceLabel
                        isAdmin={doCanManage(company)}
                        company={company}
                        settingsUrl={getCompanySettingsUrl(company)}
                        onSelect={() => chooseCompany(company)}
                        onSettings={() => handleOpenSettings(company)}
                        key={company.id}
                      />
                    )}
                  {activeCompany &&
                    <SpaceLabel
                      company={null}
                      onSelect={() => chooseCompany(null)}
                    />
                  }
                  {myCompaniesStore.companies?.length < MAX_COUNT_COMPANIES &&
                    <div
                      className={styles.companyMenu__add}
                      onClick={openCreateCompany}
                    >
                      + {t('create_company')}
                    </div>
                  }
                </MenuListContent>
              </MenuList>
            </>
          )}
      </div>
    </Breakpoint>
  )
})

const SpaceLabel = observer(function SpaceLabel({
  isActive = false,
  isAdmin = false,
  onSelect,
  onSettings,
  settingsUrl,

  company
}: {
  isActive?: boolean
  isAdmin?: boolean
  settingsUrl?: string

  onSelect: () => void
  onSettings?: () => void

  company: Company | null
}) {
  const { t } = useTranslation('layout')

  return (
    <div
      className={cn(styles.companyMenu__item, {
        [styles.companyMenu__itemActive]: isActive
      })}
      onClick={onSelect}
    >
      {company ? (
        <Avatar
          className={styles.avatar}
          avatarUrl={company.logo_path ?? ''}
          first_name={company.name ?? ''}
          color_code={company.color ?? ''}
          width={24}
          type='company'
        />
      ) : (
        <EmptyAvaRec/>
      )}

      <div className={styles.companyMenu__item__info}>
        <div className={styles.companyMenu__item__name}>
          {typeof company?.name === 'string' ? UCFirst(company.name) : t('personal_space')}
        </div>

        {company && isAdmin && (
          <div className={styles.companyMenu__item__role}>
            {t('header.company_admin')}
          </div>
        )}
      </div>

      <span className={styles.companyMenu__title}>
      </span>

      {isAdmin && (
        <a
          onClick={onSettings}
          href={settingsUrl}
          className={styles.companyMenu__edit}
        >
          <Settings/>
        </a>
      )}
    </div>
  )
})

const CreateOfferButton = observer(function CreateOfferButton() {
  const { t } = useTranslation('layout')

  const buttonRef = useRef<any>()
  const [showCreateCargo, setShowCreateCargo] = useState<boolean>(false)

  const modalsManager = useGlobalModals()

  const handleCreateCargo = () => {
    fireClickButtonCreateOffer()
    modalsManager.open(GLOBAL_MODALS_NAMES.CREATE_CARGO)
  }

  const handleCreateTransport = () => {
    fireClickButtonCreateOffer()
    modalsManager.open(GLOBAL_MODALS_NAMES.CREATE_TRANSPORT)
  }

  return (<>
    <Button
      ref={buttonRef}
      className={styles.createOffer__button}
    >
      <Add color='white' className={styles.button__icon}/> {t('header.create_offer')}
    </Button>
    <MenuList
      className={styles.createOffer__menu}
      anchorRef={buttonRef}
      placement='bottom'
      offset={[0, -41]}
      keepMounted={true}
    >
      <MenuListContent>
        <MenuDescription label={t('header.create_offer_menu.title')}/>

        <MenuListItem
          onClick={handleCreateCargo}
          className={styles.navLink}
          Component='button'
          prepend={<Inventory/>}
          label={t('header.create_offer_menu.cargo')}
        />
        <MenuListItem
          onClick={handleCreateTransport}
          className={styles.navLink}
          Component='button'
          prepend={<LocalShipping/>}
          label={t('header.create_offer_menu.vehicle')}
        />
      </MenuListContent>
    </MenuList>
  </>)
})

const AdviceTooltip = ({
  anchorRef,
  open,
  onClose
}: {
  anchorRef: any
  open: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation('layout')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [arrowRef, setArrowRef] = useState(null)

  useLayoutEffect(() => {
    if(anchorRef.current) {
      setAnchorEl(anchorRef.current)
    }
  }, [anchorRef.current])

  return (
    <Popper
      sx={{ zIndex: 999 }}
      open={open}
      anchorEl={anchorEl}
      placement='bottom-end'
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef
          }
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10]
          }
        }
      ]}
    >
      <span className={styles.tooltip__arrow} ref={(ref: any) => setArrowRef(ref)}/>
      <div className={styles.tooltip}>
        <Rocket className={styles.tooltip__icon}/>
        <p
          className={styles.tooltip__description}
          dangerouslySetInnerHTML={{ __html: t('header.tooltip') }}
        />
        <button className={styles.tooltip__close} onClick={onClose}>
          <Close width={12} heigth={12} color='#8E8E93'/>
        </button>
      </div>
    </Popper>
  )
}
