import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import qs from 'qs'

export interface Config {
  ownServerBaseUrl: string
  language: () => string | null
  accessToken: () => string | null
}

export class ApiClients {
  private config: Config

  exchange: AxiosInstance
  fileServer: AxiosInstance

  hereProxy: AxiosInstance

  identity: AxiosInstance
  events: AxiosInstance
  search: AxiosInstance

  knowledge: AxiosInstance

  messaging: AxiosInstance

  wsProxy: AxiosInstance
  notifications: AxiosInstance
  support: AxiosInstance

  nominatim: AxiosInstance

  updateConfig(config: Partial<Config>) {
    Object.assign(this.config, config)
  }

  getConfig() {
    return this.config
  }

  constructor(config: Config) {
    this.config = config

    const defaultInterceptor = (config: AxiosRequestConfig) => {
      const accessToken = this.config.accessToken()
      if(accessToken && config.headers) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
      }

      const lang = this.config.language()
      if(lang && config.headers) {
        config.headers['Accept-Language'] = lang
      }

      return config
    }

    const commonConfig = {
      timeout: 30000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Roolz-Device-Type': 'web'
      }
    }

    //--
    this.exchange = axios.create({
      paramsSerializer: (params: any) => qs.stringify(params, { encode: false, arrayFormat: 'comma' }),
      baseURL: this.config.ownServerBaseUrl + '/exchange',
      ...commonConfig
    })
    this.exchange.interceptors.request.use(defaultInterceptor)
    //--


    //--
    this.fileServer = axios.create({
      baseURL: this.config.ownServerBaseUrl + '/fileserver',
      ...commonConfig
    })
    this.fileServer.interceptors.request.use((config: AxiosRequestConfig) => ({
      ...config,
      headers: {
        ...config.headers,
        'X-Roolz-Auth': `Bearer ${this.config.accessToken()}`,
        'Accept-Language': this.config.language()
      }
    }))
    //--


    //--
    this.hereProxy = axios.create({
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      baseURL: this.config.ownServerBaseUrl + '/here-proxy',
      ...commonConfig
    })
    this.hereProxy.interceptors.request.use((config: AxiosRequestConfig) => ({
      ...config,
      headers: {
        ...config.headers,
        'X-Roolz-Auth': `Bearer ${this.config.accessToken()}`,
        'Accept-Language': this.config.language()
      }
    }))
    //--


    //--
    this.identity = axios.create({
      baseURL: this.config.ownServerBaseUrl + '/identity',
      ...commonConfig,
      headers: {
        ...commonConfig.headers,
        "Roolz-Product": "business",
      },
    })
    this.identity.interceptors.request.use(defaultInterceptor)
    //--

    //--
    this.events = axios.create({
      baseURL: this.config.ownServerBaseUrl + '/events',
      ...commonConfig,
      headers: {
        ...commonConfig.headers,
        "Roolz-Product": "business",
      },
    })
    this.events.interceptors.request.use(defaultInterceptor)
    //--

    //--
    this.search = axios.create({
      baseURL: this.config.ownServerBaseUrl + '/search',
      ...commonConfig,
      headers: {
        ...commonConfig.headers,
        "Roolz-Product": "business",
      },
    })
    this.search.interceptors.request.use(defaultInterceptor)
    //--

    //--
    this.knowledge = axios.create({
      baseURL: this.config.ownServerBaseUrl + '/knowledge',
      ...commonConfig,
    })
    this.knowledge.interceptors.request.use(defaultInterceptor)
    //--

    //--
    this.notifications = axios.create({
      baseURL: this.config.ownServerBaseUrl + '/notifications',
      paramsSerializer: (params: any) => qs.stringify(params, { encode: false, arrayFormat: 'comma' }),
      ...commonConfig,
    })
    this.notifications.interceptors.request.use(defaultInterceptor)
    //--


    //--
    this.messaging = axios.create({
      baseURL: this.config.ownServerBaseUrl + '/messaging',
      ...commonConfig,
      headers: {
        ...commonConfig.headers,
        "Roolz-Product": "business"
      }
    })
    this.messaging.interceptors.request.use(defaultInterceptor)
    //--


    //--
    this.wsProxy = axios.create({
      baseURL: this.config.ownServerBaseUrl + '/ws',
      ...commonConfig,
    })
    this.wsProxy.interceptors.request.use(config => ({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `${this.config.accessToken()}`,
      }
    }))
    //--


    //--
    this.support = axios.create({
      baseURL: this.config.ownServerBaseUrl + '/support',
      ...commonConfig,
      headers: {
        ...commonConfig.headers,
        "Roolz-Product": "business",
      },
    })
    this.support.interceptors.request.use(defaultInterceptor)
    //--


    //--
    this.nominatim = axios.create({
      baseURL: 'https://nominatim.openstreetmap.org',
      ...commonConfig,
    })
    this.nominatim.interceptors.request.use(config => ({
      ...config,
      headers: {
        ...config.headers,
        'Accept-Language': this.config.language()
      }
    }))
    //--
  }
}
