import { UserPlaceholder } from '@roolz/icons/avatar/UserPlaceholder'
import { CompanyPlaceholder } from '@roolz/icons/avatar/CompanyPlaceholder'
import { getFileserverAvatarLinkByPath } from '@roolz/sdk/utils/cdns'
import { Url } from '@roolz/types/scalars'
import cn from 'classnames'
import * as React from 'react'
import { CSSProperties, forwardRef, memo, ReactNode, useMemo } from 'react'
import styles from './Avatar.module.scss'
import { OnlineStatus } from '@roolz/types/custom'
import { DeletedProfileAvatar } from '@roolz/icons/DeletedProfileAvatar'

const WIDTH_FONT_SIZE_MULTIPLIER = 0.4

export interface Props {
  width: number

  avatarUrl?: Url | null
  first_name?: string | null
  last_name?: string | null
  color_code?: string
  is_deleted?: boolean

  type?: 'profile' | 'company'
  onlineStatus?: OnlineStatus

  children?: ReactNode
  className?: string

  customBorderRadius?: string
}

export const Avatar = memo(forwardRef(({
  avatarUrl,
  first_name = '',
  last_name = '',
  color_code,
  width,
  is_deleted,

  type = 'profile',
  onlineStatus = OnlineStatus.Offline,

  children,
  className,

  customBorderRadius

}: Props, ref: any) => {
  const height = width

  const avatarLetters: string = useMemo(
    () => getAvatarLetters({ first_name, last_name, type }),
    [first_name, last_name, type]
  )

  const FallbackImage = () => type === 'profile'
    ? <ProfileFallbackImage/>
    : <CompanyFallbackImage/>

  function LettersAvatar() {
    return (
      <div
        className={cn({
          [styles.profilePlaceholder]: type === 'profile',
          [styles.companyPlaceholder]: type === 'company'
        })}
        style={{
          backgroundColor: color_code ?? '#000',
          fontSize: width * WIDTH_FONT_SIZE_MULTIPLIER
        }}
      >
        {avatarLetters}
      </div>
    )
  }

  return (
    <div
      ref={ref}
      style={{
        width,
        height,
        // borderRadius: type === 'company' ? width * 0.16 : undefined
        '--company-border-radius': customBorderRadius
      } as CSSProperties}

      className={cn(styles.avatar, className, {
        [styles.avatarCompany]: type === 'company',
        [styles.online]: onlineStatus === OnlineStatus.Online,
        [styles.onlineRecently]: onlineStatus === OnlineStatus.Recently,
      })}
    >
      {is_deleted ? (
        <DeletedImage/>
      ) : (<>
        {avatarUrl ? (
          <img
            className={cn(styles.image, {
              [styles.image__company]: type === 'company'
            })}
            style={{
              // borderRadius: type === 'company' ? width * 0.16 : undefined
            }}
            src={getFileserverAvatarLinkByPath(avatarUrl, { width, height })}
            alt={avatarLetters}
          />
        ) : (
          avatarLetters ? <LettersAvatar/> : <FallbackImage/>
        )}
      </>)}

      {children}
    </div>
  )
}))

function DeletedImage() {
  return (
    <DeletedProfileAvatar/>
  )
}

function ProfileFallbackImage() {
  return (
    <UserPlaceholder
      className={styles.profilePlaceholder}
    />
  )
}

function CompanyFallbackImage() {
  return (
    <div className={styles.companyPlaceholder}>
      <CompanyPlaceholder
        className={styles.companyPlaceholder__img}
      />
    </div>
  )
}

/*
 Helpers
 */

function getAvatarLetters({
  first_name,
  last_name,
  type
}: Pick<Props, 'first_name' | 'last_name' | 'type'>) {
  const names = [first_name]

  if (type === 'profile') {

    names.push(last_name)
  }

  return names
    .map(name => {
      const nonSymbolicName = name ? name.replace(/[^ \p{N}\p{L}-]+/ug, '') : ''
      const preparedName = nonSymbolicName.length ? nonSymbolicName : name

      return preparedName && getFirstLetter(preparedName).toUpperCase()
    })
    .join('')
}

const getFirstLetter = (name?: string) => (name?.trim()?.[0] ?? '')
