import { Check } from '@roolz/icons/Check'
import { MenuItem, SelectProps, TextField } from '@mui/material'
import { ExpandButton } from '@roolz/sdk/components/ui/buttons/ExpandButton/ExpandButton'
import { useWhyDidYouUpdate } from 'ahooks'
import cn from 'classnames'
import { observable } from 'mobx'
import { observer } from 'mobx-react-lite'
import { forwardRef, memo, ReactNode, useEffect, useState } from 'react'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import { v4 as uuidv4 } from 'uuid'
import styles from './Select.module.scss'

export interface SelectItem {
  value: string
  label: ReactNode
}

interface Props {
  value?: string | string[]
  defaultValue?: string | string[]
  onChange?: (item: any) => void,
  items?: SelectItem[]
  children?: ReactNode
  SelectProps?: Partial<SelectProps>
  allowEmpty?: boolean
  emptyLabel?: string
  placeholder?: string
  popoverOffset?: { vertical?: number, horizontal?: number }

  [key: string]: any
}

export const Select = observer(forwardRef((props: Props, ref: any) => {
  const {
    value,
    defaultValue,
    onChange,
    items = [],
    children,
    allowEmpty = true,
    emptyLabel,
    placeholder,
    SelectProps,
    popoverOffset,
    ...rest
  } = props

  const { t } = useSdkTranslation('ui')
  const [val, setVal] = useState<any | null>(defaultValue ?? '')

  // function handleChange(e: any) {
  //   setVal(e.target.value)
  //   if(onChange) {
  //     onChange({
  //       target: {
  //         name: e.target.name,
  //         value: e.target.value
  //       }
  //     })
  //   }
  // }

  function getItemByValue(value: SelectItem['value']): SelectItem | undefined {
    return items.find(item => item.value === value)
  }

  useEffect(() => {
    setVal(value ?? defaultValue ?? '')
  }, [value])

  return (
    <TextField
      select
      size='small'
      SelectProps={{
        inputRef: ref,
        // autoWidth: true,
        IconComponent: ExpandButton,
        classes: {
          select: styles.selectField,
          icon: styles.icon,
          iconOpen: styles.iconOpen
        },
        // native: false,
        MenuProps: {
          classes: {
            paper: styles.menu,
            list: styles.menu__list
          },
          PaperProps: {
            style: {
              maxHeight: 40 * 6
            }
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
            ...popoverOffset
          }
        },
        displayEmpty: true,
        renderValue: (value): any => {
          const item = getItemByValue(value as string)

          if(!item) {
            if(allowEmpty) {
              return (
                <div className={styles.value}>
                  {emptyLabel ?? t('select.empty')}
                </div>
              )
            } else {
              return (
                <div className={cn(styles.value__placeholder, styles.value)}>
                  {placeholder}
                </div>
              )
            }
          }

          return (
            <div className={styles.value}>
              {item.label}
            </div>
          )
        },
        ...SelectProps
      }}
      value={val}
      onChange={onChange}
      {...rest}
    >
      {allowEmpty && (
        <MenuItem
          value=''
          classes={{ root: styles.item }}
        >
          {emptyLabel ?? t('select.empty')}
        </MenuItem>
      )}
      {items.map(item => (
        <MenuItem
          key={item.value}
          value={item.value}
          classes={{ root: styles.item }}
        >
          {item.label}
          {item.value !== '' && (
            <Check className={styles.item__check}/>
          )}
        </MenuItem>
      ))}
    </TextField>
  )
}))
