import { refreshTokensInterceptor } from '@/api/refreshTokensInterceptor'
import i18n from '@/plugins/i18n'

import { authStore } from '@/store/auth/auth.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { ApiClients } from '@roolz/api/clients'
import { createApi } from '@roolz/api/index'
import { AxiosRequestConfig } from 'axios'

const clients = new ApiClients({
  ownServerBaseUrl: process.env.REACT_APP_OWN_SERVER_BASE_URL!,
  accessToken: () => authStore.token,
  language: () => i18n.language
})

const activeSpaceInterceptor = (config: AxiosRequestConfig) => {
  if(config.params?.active_space_company_id === undefined && profilesStore.activeCompanyId) {
    config.params = {
      ...config?.params,
      active_space_company_id: profilesStore.activeCompanyId
    }
  }

  return config
}

clients.exchange.interceptors.request.use(refreshTokensInterceptor)
clients.exchange.interceptors.request.use(activeSpaceInterceptor)

clients.identity.interceptors.request.use(activeSpaceInterceptor)
clients.identity.interceptors.request.use(refreshTokensInterceptor)
clients.knowledge.interceptors.request.use(refreshTokensInterceptor)
clients.messaging.interceptors.request.use(refreshTokensInterceptor)
clients.wsProxy.interceptors.request.use(refreshTokensInterceptor)
clients.fileServer.interceptors.request.use(refreshTokensInterceptor)
clients.hereProxy.interceptors.request.use(refreshTokensInterceptor)


export const api = createApi(clients)
