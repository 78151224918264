import { MapGeoRequest } from '@roolz/types/api/here-proxy'
import { AxiosInstance } from 'axios'
import { ApiClients } from '@roolz/api/clients'

export class HereProxyApi {
  hereProxy: AxiosInstance

  constructor(clients: ApiClients) {
    this.hereProxy = clients.hereProxy
  }

  search(params: MapGeoRequest) {
    return this.hereProxy.get('/mapgeo/v1/search', { params })
  }

  notifySelection(osm_id: string) {
    return this.hereProxy.get<void>('/mapgeo/v1/search/selected', { params: { osm_id } })
  }
}

