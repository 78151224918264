import { Message } from '@roolz/types/api/chats'
import { ContactInfo } from '@roolz/types/api/profiles'
import { evebus } from 'evebus'

export type Events = {
  'contacts/contactAdded': ContactInfo
  'messages/messageCreated': Message
}

export const bus = evebus<Events>()
