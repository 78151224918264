import { Card } from '@/components/modals/PrivateExchangeGuide/Card/Card'
import { CompanyCreateBanner } from '@/components/modals/PrivateExchangeGuide/CompanyCreateBanner/CompanyCreateBanner'
import { Dialog } from '@/components/ui/Dialog/Dialog'
import { MAX_COUNT_COMPANIES } from '@/config/const'
import { fireCompanyCreateButton } from '@/config/events'
import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { GLOBAL_MODALS_NAMES } from '@/global-modals/GlobalModalsProvider'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { companiesStore } from '@/store/companies/companies.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import BusinessCenterIcon from '@material-symbols/svg-400/rounded/business_center.svg'
import GroupAddIcon from '@material-symbols/svg-400/rounded/group_add.svg'
import ScheduleIcon from '@material-symbols/svg-400/rounded/schedule.svg'
import Warning from '@material-symbols/svg-400/rounded/warning.svg'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import { Question } from '@roolz/sdk/components/ui/FAQ/Question/Question'
import { SelectType } from '@roolz/sdk/components/ui/SelectType/SelectType'
import { ExchangeRoleSlug } from '@roolz/types/api/knowledge/companies'
import { observer } from 'mobx-react-lite'
import React, { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styles from './PrivateExchangeGuide.module.scss'

interface Props {
  open: boolean
  onClose: () => void
}

enum UserTypes {
  Customer = 'customer',
  Shipper = 'shipper',
}

export const PrivateExchangeGuide = observer((props: Props) => {
  const { open, onClose } = props

  const { t } = useTranslation('modals')

  const handleClose = () => {
    onClose()
  }

  const navigate = useNavigate()

  const handleCreateCompany = () => {
    onClose()
    fireCompanyCreateButton()

    navigate(resolvePathByName(ROUTE_NAMES.COMPANY_CREATE), {state: { prevLocation: location }})
  }

  const isSelfEmployed = !profilesStore.my_profile?.active_space_company_id

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      open={open}
      onClose={handleClose}
      scroll='body'
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h2 className={styles.header__text}>
            {t('private_exchange_guide.title')}
          </h2>

          <CloseButton
            className={styles.close}
            onClick={onClose}
          />
        </div>

        <div className={styles.description}>
          {t('private_exchange_guide.description')}

          {isSelfEmployed && (
            <span className={styles.description__companiesOnly}>
              <MaterialSymbolIcon
                icon={<Warning/>}
                className={styles.description__companiesOnly__icon}
              /><wbr/>
              {t('private_exchange_guide.only_for_companies')}
            </span>
          )}
        </div>

        {isSelfEmployed && myCompaniesStore.companies?.length < MAX_COUNT_COMPANIES && (
          <CompanyCreateBanner onCreate={handleCreateCompany}/>
        )}
        <Cards/>
        <Suspense>
          <Faq/>
        </Suspense>

        <div className={styles.footer}>
          <Button
            className={styles.footer__close}
            onClick={onClose}
          >
            {t('private_exchange_guide.close')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
})

const Cards = observer(() => {
  const { t } = useTranslation('modals')

  const [role, setRole] = useState(() => {
    if(companiesStore.activeCompany?.exchange_role) {
      return [ExchangeRoleSlug.carrier, ExchangeRoleSlug.dispatcher].includes(companiesStore.activeCompany?.exchange_role)
        ? UserTypes.Customer
        : UserTypes.Shipper
    }

    return UserTypes.Shipper
  })

  const types = [
    {
      value: UserTypes.Shipper,
      title: t('private_exchange_guide.cards.shipper.name')
    },
    {
      value: UserTypes.Customer,
      title: t('private_exchange_guide.cards.customer.name')
    }
  ]

  const cards = [
    {
      Icon: GroupAddIcon,
      i18nPrefix: `private_exchange_guide.cards.${role}.1`
    },
    {
      Icon: ScheduleIcon,
      i18nPrefix: `private_exchange_guide.cards.${role}.2`
    },
    {
      Icon: BusinessCenterIcon,
      i18nPrefix: `private_exchange_guide.cards.${role}.3`
    }
  ]

  return (
    <div>
      <div className={styles.roles}>
        <SelectType
          value={role}
          onChange={setRole}
          items={types}

          activeBgColor='#4778EE'
          className={styles.roles__select}
          itemClassName={styles.roles__select__item}
          activeItemClassName={styles.roles__select__itemActive}
        />
      </div>


      <div className={styles.cards}>
        {cards.map(({ i18nPrefix, Icon }) => (
          <Card
            key={i18nPrefix}
            Icon={<Icon/>}
            title={t(i18nPrefix + '.title')}
            description={t(i18nPrefix + '.description')}
          />
        ))}
      </div>
    </div>
  )
})

const Faq = () => {
  const { t } = useTranslation('modals')
  const { t: faqT } = useTranslation('faq')

  const questions = (faqT('private_exchange', { returnObjects: true }) || []) as { title: string, description: string }
  const questionsBusiness = (faqT('business_private_exchange', { returnObjects: true }) || []) as { title: string, description: string }

  return (
    <div className={styles.faq}>
      <h1 className={styles.faq__title}>
        {t('private_exchange_guide.faq.title')}
      </h1>

      <div
        itemScope
        itemType='https://schema.org/FAQPage'
        className={styles.faq__list}
      >
        {Array.isArray(questionsBusiness) && questionsBusiness.map((item, i) => (
          <Question
            key={i}
            title={item.title}
            description={item.description}

            theme='dark-outline'
            mode='compact'
          />
        ))}
        {Array.isArray(questions) && questions.map((item, i) => (
          <Question
            key={i}
            title={item.title}
            description={item.description}

            theme='dark-outline'
            mode='compact'
          />
        ))}
      </div>
    </div>
  )
}
