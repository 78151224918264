export interface AdrClass {
  slug: AdrClassSlug
  index: number
  cargo: boolean
  transport: boolean

  cargo_default?: boolean
}

export interface CargoType {
  slug: CargoTypeSlug
  index: number
  is_quantity: boolean
}

export interface ExtraEquipment {
  slug: ExtraEquipmentSlug
  index: number
  group: ExtraEquipmentGroup
}

export interface LoadingType {
  slug: LoadingTypeSlug
  index: number
}

export interface PaymentCondition {
  slug: PaymentConditionSlug
  index: number
  period: boolean

  cargo_default?: boolean
}

export interface PaymentMethod {
  slug: PaymentMethodSlug
  index: number
}

export interface PointType {
  slug: PointTypeSlug
  index: number
  loading_type: boolean
}

export interface PublicationAutocancelReason {
  slug: PublicationAutocancelReasonSlug
  index: number
}

export interface ShippingMode {
  slug: ShippingModeSlug
  index: number
  is_quantity: boolean
  cargo: boolean
  transport: boolean

  cargo_default: boolean
}

export interface TransportType {
  slug: TransportTypeSlug
  index: number
  transport: boolean
  cargo: boolean

  cargo_default?: boolean
}

export interface TruckBody {
  slug: TruckBodySlug
  index: number
  type: TruckBodyType
  transport_types: TransportTypeSlug[]

  cargo_default?: boolean
}

export interface Currency {
  slug: CurrencySlug
  index: number
  flag: string
  symbol: string
  countries: string[]
  is_symbol_left: boolean
  is_symbol_local: boolean
  is_symbol_code: boolean
}

export enum AdrClassSlug {
  no = 'no',
  any_dangerous = 'any_dangerous',
  adr_1 = 'adr_1',
  adr_2_1 = 'adr_2_1',
  adr_2_2 = 'adr_2_2',
  adr_2_3 = 'adr_2_3',
  adr_3 = 'adr_3',
  adr_4_1 = 'adr_4_1',
  adr_4_2 = 'adr_4_2',
  adr_4_3 = 'adr_4_3',
  adr_5_1 = 'adr_5_1',
  adr_5_2 = 'adr_5_2',
  adr_6_1 = 'adr_6_1',
  adr_6_2 = 'adr_6_2',
  adr_7 = 'adr_7',
  adr_8 = 'adr_8',
  adr_9 = 'adr_9'
}

export enum CargoTypeSlug {
  eur_pallet = 'eur_pallet',
  fin_pallet = 'fin_pallet',
  oth_pallet = 'oth_pallet',
  carton = 'carton',
  piece = 'piece',
  container = 'container',
  groupage = 'groupage',
  oversized = 'oversized',
  long = 'long',
  dry_bulk = 'dry_bulk',
  break_bulk = 'break_bulk',
  liquid_bulk = 'liquid_bulk',
  bag = 'bag',
  big_bag = 'big_bag',
  icb = 'icb',
  barrel = 'barrel',
  roll = 'roll',
  reel = 'reel',
  coil = 'coil',
  crate = 'crate'
}

export enum ExtraEquipmentSlug {
  bunk_rack = 'bunk_rack',
  low_bed = 'low_bed',
  double_deck = 'double_deck',
  extender = 'extender',
  coil_well = 'coil_well',
  multitemperature = 'multitemperature',
  meat_hooks = 'meat_hooks',
  crane = 'crane',
  walking_floor = 'walking_floor',
  grapple = 'grapple',
  tail_lift = 'tail_lift',
  rear_ramp = 'rear_ramp',
  hook_loader = 'hook_loader',
  loader = 'loader'
}

type ExtraEquipmentGroup = string

export enum LoadingTypeSlug {
  rear = 'rear',
  side = 'side',
  side_by_side = 'side_by_side',
  rack_off = 'rack_off',
  top = 'top',
  tent_off = 'tent_off'
}

export enum PaymentConditionSlug {
  deferred_upon_originals = 'deferred_upon_originals',
  deferred_upon_copies = 'deferred_upon_copies',
  payment_on_unloading = 'payment_on_unloading',
  other_terms = 'other_terms'
}

export enum PaymentMethodSlug {
  wire = 'wire',
  cash = 'cash',
  card = 'card',
  mixed = 'mixed',
  other = 'other'
}

export enum PointTypeSlug {
  loading = 'loading',
  unloading = 'unloading',
  border = 'border',
  customs = 'customs',
  waypoint = 'waypoint'
}

export enum RouteStartDateOffsetDays {
  'ZERO',
  'ONE',
  'TWO'
}

export enum PublicationAutocancelReasonSlug {
  time_expiration = 'time_expiration',
  assignee_removed = 'assignee_removed',
  moderator = 'moderator'
}

export enum ShippingModeSlug {
  any = 'any',
  ftl = 'ftl',
  ltl = 'ltl',
  special = 'special'
}

export enum TransportTypeSlug {
  any = 'any',
  lcv = 'lcv',
  solo = 'solo',
  lorry = 'lorry',
  special_machinery = 'special_machinery',
  double_trailer = 'double_trailer'
}

export enum TruckBodySlug {
  curtainside = 'curtainside',
  box = 'box',
  insulated = 'insulated',
  ref = 'ref',
  open_body = 'open_body',
  flat_bed = 'flat_bed',
  deeploader = 'deeploader',
  tipper = 'tipper',
  meat_hooker = 'meat_hooker',
  cont_carrier = 'cont_carrier',
  cont_open = 'cont_open',
  cont_closed = 'cont_closed',
  tank_cont = 'tank_cont',
  tank_fuel = 'tank_fuel',
  tank_bitumen = 'tank_bitumen',
  tank_gas = 'tank_gas',
  tank_chemical = 'tank_chemical',
  tank_food = 'tank_food',
  silo_food = 'silo_food',
  silo_non_food = 'silo_non_food',
  tank_other = 'tank_other',
  car_carrier = 'car_carrier',
  log_carrier = 'log_carrier',
  cattle_carrier = 'cattle_carrier',
  other = 'other',
  crane_truck = 'crane_truck',
  lift_truck = 'lift_truck',
  tow_truck = 'tow_truck',
  road_tractor = 'road_tractor',
  tractor = 'tractor',
  grain_truck = 'grain_truck',
  dozer = 'dozer',
  loader = 'loader'
}

export type TruckBodyType = string
export type CurrencySlug = string
